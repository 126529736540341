import type { MapboxGeoJSONFeature } from "mapbox-gl"
import mapboxgl from "mapbox-gl"
import type React from "react"
import { Layer, Source } from "react-map-gl/mapbox"
import { mapboxConfig, type MapboxConfig } from "./mapboxConfig"

export const MAPBOX_STYLE =
  "mapbox://styles/thepublichealthco/cl4d7pbah000i14oaclynyy2x"

const riskPaintConfig = {
  // first set color by fillColor,
  // if no fill color, check for active state
  // default to transparent
  "fill-color": [
    "string",
    [
      "case",
      [
        "all",
        ["to-boolean", ["feature-state", "fillColor"]],
        ["!=", ["feature-state", "fillColor"], "transparent"],
      ],
      ["feature-state", "fillColor"],
      ["boolean", ["feature-state", "isActive"], false],
      "white",
      "transparent",
    ],
  ],
  "fill-opacity": [
    "case",
    ["boolean", ["feature-state", "isActive"], false],
    0.7,
    0.4,
  ],
} satisfies mapboxgl.FillPaint

const getLineWidth = (context: MapboxConfig["sourceLayer"][0]["context"]) => {
  switch (context) {
    case "country":
      return 6
    case "subnational":
      return 6
    case "subdivision":
      return 6
  }
}

export const MapSourceLayers: React.FC<{
  firstSymbolId?: string
}> = ({ firstSymbolId }) => {
  return Array.from(mapboxConfig.allBySource.entries()).map(
    ([countryCode, layers]) => {
      return (
        <Source
          id={countryCode}
          type="vector"
          url={layers[0]?.sourceInfo.sourceUrl}
          key={countryCode}
        >
          {layers.map(layer => {
            const layerConfig = {
              source: countryCode,
              "source-layer": layer.id,
              minzoom: layer.defaultZoomLevels.minzoom,
              maxzoom: layer.defaultZoomLevels.maxzoom,
            }
            return [
              <Layer
                id={layer.id}
                key={layer.id}
                type="fill"
                {...layerConfig}
                paint={riskPaintConfig}
                beforeId={firstSymbolId}
              />,

              <Layer
                type="line"
                id={`${layer.id}-line`}
                key={`${layer.id}-line`}
                {...layerConfig}
                maxzoom={22}
                paint={{
                  "line-color": "#687A95",
                  "line-width": [
                    // The idea here is to make the country and state borders more defined while zoomed in
                    // but not super ugly when zoomed out
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    layer.defaultZoomLevels.minzoom,
                    0.5,
                    16,
                    [
                      "*",
                      getLineWidth(layer.context),
                      // if the line is active, make it twice as thick
                      [
                        "case",
                        ["boolean", ["feature-state", "isActive"], false],
                        2,
                        1,
                      ],
                    ],
                  ],
                }}
                beforeId={firstSymbolId}
              />,
              <Layer
                type="line"
                id={`${layer.id}-highlight`}
                key={`${layer.id}-highlight`}
                source={layerConfig.source}
                source-layer={layerConfig["source-layer"]}
                // layer only loaded if active
                // for performance reasons
                minzoom={22}
                maxzoom={22}
                paint={{
                  "line-color": [
                    "string",
                    ["feature-state", "lineColor"],
                    "transparent",
                  ],
                  "line-width": 2.5,
                }}
                beforeId={firstSymbolId}
              />,
            ]
          })}
        </Source>
      )
    }
  )
}

export type PhcFeature = GeoJSON.Feature<GeoJSON.Geometry, CountyProperties> &
  MapboxGeoJSONFeature

export const isPhcFeature = (
  feature: GeoJSON.Feature | undefined
): feature is PhcFeature => {
  const properties = feature?.properties as CountyProperties | undefined
  return (
    properties?.location_name != null &&
    properties.bbox_bottom != null &&
    properties.bbox_left != null &&
    properties.bbox_right != null &&
    properties.bbox_top != null
  )
}

export const findFeatureBoundingBox = (
  feature:
    | GeoJSON.Feature<GeoJSON.Geometry, CountyProperties>
    | MapboxGeoJSONFeature
) => {
  // protect against bad props
  if (!isPhcFeature(feature)) return
  const boundingBoxProps = feature.properties
  const featureBB = [
    boundingBoxProps.bbox_left ?? 0,
    boundingBoxProps.bbox_bottom ?? 0,
    boundingBoxProps.bbox_right ?? 0,
    boundingBoxProps.bbox_top ?? 0,
  ] as const
  return new mapboxgl.LngLatBounds([
    featureBB[0],
    featureBB[1],
    featureBB[2],
    featureBB[3],
  ])
}

/** https://public-health-company.atlassian.net/wiki/spaces/PE/pages/638681153/Feature+properties */
export interface CountyProperties {
  /** OSM name */
  name: string
  country?: string
  bbox_left?: number
  bbox_bottom?: number
  bbox_right?: number
  bbox_top?: number
  centroid?: string
  country_code?: string
  location_code?: string
  /** Internal PHC location name */
  location_name?: string
}
